$theme-colors: (
  "primary": #0077bf,
  "secondary": #ef4e58,
);

$white: #fff;
$black: #000;
$red: #ef4e58;
$grey: #52545b;
$green: #219653;

$primary-brand-color: #0077bf;
$primary-brand-color--dark: #016cad;
$secondary-brand-color: #ef4e58;
$secondary-brand-color--dark: #f93d49;

$primary-text-color: #000a30;
$secondary-text-color: #555555;
$teritary-text-color: #888888;

$text-dark: #211f1f;
$title-color: #1f1f1f;
$body-color: #363636;
$border-color: #d7dadb;
$background-color: #f8f8f8;

$shared-subscription-text-color: #F79F1F;
$sub-agency-text-color: #c23616;

// /* font weight */
$very-light: 100;
$light: 300;
$light-italic: 300;
$regular: 400;
$regular-italic: 400;
$medium: 500;
$semibold: 600;
$semibold-italic: 600;
$bold: 700;
$bold-italic: 700;

/* img path */
$img-path: "../img";
$img-icons-path: "../img/icons";

/* break points*/
$bp-mobile: 600px;
$bp-mobile-ls: 767px;
$bp-tablet: 992px;
$bp-large: 1300px;

$color1: #673ab7;
$color2: #818100;
$color3: #a4884f;
$color4: #1c6adf;
$color5: #795548;
$color6: #607d8b;
$color7: #4c516d;
$color8: #008081;
$color9: #00bcd4;
$color10: #9c27b0;
$color11: #f6578d;
$color12: #e91e63;
$color13: #2196f3;
$color14: #0e4d92;
$color15: #111e6c;
// $color1: #e91e63;
// $color2: #9c27b0;
// $color3: #673ab7;
// $color4: #2196f3;
// $color5: #00bcd4;
// $color6: #ff5722;
// $color7: #795548;
// $color8: #607d8b;
// $color9: #008081;
// $color10: #818100;
// $color11: #a4884f;
// $color12: #f6578d;
// $color13: #4c516d;
// $color14: #0e4d92;
// $color15: #111e6c;

// $color1: #7ef9ff;
// $color2: #14d9ac;
// $color3: #4f97a3;
// $color4: #1c6adf;
// $color5: #1034a6;
// $color6: #000080;
// $color7: #101741;
// $color8: #022136;
// $color9: #213063;
// $color10: #020202;
// $color11: #3fe0d0;
// $color12: #008081;
// $color13: #4c516d;
// $color14: #0e4d92;
// $color15: #111e6c;

// #14d9ac

// $color4: #1c6adf; selected

// #e91e63
// #9c27b0
// #673ab7
// #2196f3
// #00bcd4
// #ff5722
// #795548
// #607d8b
// #008081
// #818100
// #a4884f
// #f6578d
