/// @group functions
/// @type Number
/// @group global
$rem-base: 16 !default;

/// @param {Number} $num - Number to strip unit from.
///
/// @returns {Number} The same number, sans unit.
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [$rem-base] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px.
///
/// @returns {List} A list of converted values.
@function rem-calc($values, $base: $rem-base) {
    $rem-values: ();
    $count: length($values);

    @if $base==null {
        $base: 16px;
    }

    @if $count==1 {
        @return -zf-to-rem($values, $base);
    }

    @for $i from 1 through $count {
        $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
    }

    @return $rem-values;
}

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
@function -zf-bp-to-em($value) {
    // Pixel and unitless values are converted to rems
    @if unit($value) == "px" or unitless($value) {
        $value: rem-calc($value);
    }

    // Then the value is converted to ems
    @return strip-unit($value) * 1em;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$rem-base` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [$rem-base] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -zf-to-rem($value, $base: $rem-base) {
    // Calculate rem if units for $value is not rem
    @if (unit($value) != "rem") {
        $value: strip-unit($value) / strip-unit($base) * 1rem;
    }

    // Turn 0rem into 0
    @if ($value==0rem) {
        $value: 0;
    }

    @return $value;
}

/*
This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
It will generate several classes such as:
.m-r-10 which gives margin-right 10 pixels.
.m-r-15 gives MARGIN to the RIGHT 15 pixels.
.m-t-15 gives MARGIN to the TOP 15 pixels and so on.
.p-b-5 gives PADDING to the BOTTOM of 5 pixels
.p-l-40 gives PADDING to the LEFT of 40 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/
$spaceamounts: (
    5,
    7,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    55,
    60,
    65,
    70,
    75,
    80,
    85,
    90,
    95,
    100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }

        .p-#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }
    }
}

// Media queries mixin
$breakpoint-small: 600px;
$breakpoint-med-small: 960px;
$breakpoint-med: 1175px;

@mixin screen($size, $type: max, $pixels: $breakpoint-small) {
    @if $size== "small" {
        @media screen and ($type + -width: $breakpoint-small) {
            @content;
        }
    } @else if $size== "med-small" {
        @media screen and ($type + -width: $breakpoint-med-small) {
            @content;
        }
    } @else if $size== "med" {
        @media screen and ($type + -width: $breakpoint-med) {
            @content;
        }
    } @else if $size== "large" {
        @media screen and ($type + -width: $breakpoint-med) {
            @content;
        }
    } @else if $size== "custom" {
        @media screen and ($type + -width: $pixels + px) {
            @content;
        }
    } @else {
        @content;
    }
}

//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}

//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
}


.visibility-hidden{
    visibility: hidden;
}