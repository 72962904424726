.btn {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border-radius: 2px;
  transition: all 0.3s ease;
  &--primary {
    padding: 10px 10px;
    background: $primary-brand-color;
    &:hover {
      background: darken($primary-brand-color, 10%);
    }
    &.btn--disabled {
      color: $white;
      opacity: 0.5;
      pointer-events: none;
      background: $primary-brand-color;
      border: none;
    }
  }
  &--secondary {
    padding: 10px 10px;
    background: $secondary-brand-color;
    &:hover {
      background: darken($secondary-brand-color, 15%);
    }
    &.btn--disabled {
      color: $white;
      opacity: 0.5;
      pointer-events: none;
      background: $secondary-brand-color;
      border: none;
    }
  }
  &--teritary {
    color: #373f48;
    padding: 7px 15px;
    background: $white;
    border: 1px solid #869399;
    transition: all 0.3s ease;
    &:hover {
      background: #f4f8f9;
    }
  }
  &--white {
    padding: 10px 10px;
    font-weight: 500;
    color: $grey;
    background: #ffffff;
    border: 1px solid #d7dadb;

    &:hover {
      background: rgba($primary-brand-color, 0.1);
    }
  }
  &--disabled {
    color: $grey;
    opacity: 0.3;
    pointer-events: none;
    background: #ffffff;
    border: 1px solid #869399;
  }
  &--large {
    width: 100%;
    padding: 11px;
  }
  &--small {
    padding: 8px 10px;
  }
  &--large2 {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    padding: 13px;
  }
}
